.activeAction {
  color: #006FCF;
  font-size: 18px;
}

.inactiveAction {
  color: #ECF6FF;
  font-size: 18px;
}

.grid2Col {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
}

.noPaddingContentBox {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
    }
  }
}
